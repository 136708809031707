<template>
  <div class="home">
    <a-affix :offset-top="top">
      <div class="header" style="background-color: white; position: fixed; top: 0; width: 100%; z-index: 1000;">
        <a-layout>
          <a-layout-header class="header" style="background-color: white;">
            <div class="logo">
              <img src="../assets/logo1@2x.png" alt="" @click="gofilequan">
            </div>
            <div class="right">
              <div class="liarbry-text" @click="goFiles" style="color: cornflowerblue;cursor: pointer;">{{ libraryText
                }}
              </div>
            </div>
          </a-layout-header>
        </a-layout>
      </div>
    </a-affix>
    <div class="chat-container">
      <div class="messages">
        <div v-for="(message, index) in messages" :key="index" class="message">
          <div :class="{ 'user-message': message.role === 'user', 'assistant-message': message.role === 'assistant' }">
            <img v-if="message.role === 'assistant'" src="../assets/机器人.png" alt="Assistant" class="message-icon" />
            <div v-html="formatMessage(message.content)"></div> <!-- 使用v-html展示解析后的内容 -->
            <img v-if="message.role === 'user'" src="../assets/用户.png" alt="User" class="message-icon" />
          </div>

          <!-- Display children if they exist -->
          <div v-if="message.children && message.children.length > 0" class="children-container">
            <a-collapse v-model:activeKey="activeKey" collapsible="header" accordion>
              <a-collapse-panel :key="index" header="知识库匹配结果">
                <div v-for="(child, childIndex) in message.children" :key="childIndex" class="child-message">
                  <p v-html="formatMessage(child.content)"></p> <!-- 同样对children内容解析展示 -->
                </div>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </div>
        <div v-if="currentMessage" class="current-message">
          <span class="assistant-message">
            <img src="../assets/机器人.png" alt="Assistant" class="message-icon" />
            <div v-html="formatMessage(currentMessage)"></div>
          </span>
        </div>
        <div v-if="currentMemo" class="current-message">
          <span class="assistant-message">
            <img src="../assets/机器人.png" alt="Assistant" class="message-icon" />
            <div v-html="formatMessage(currentMemo)"></div>
          </span>
        </div>
      </div>
      <div style="position: fixed; bottom: 0;  padding: 16px; box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
        <div style="position: relative;" class="input-container">
          <a-textarea v-model:value="userInput" placeholder="档案专业问题，即问即答!" :auto-size="{ minRows: 5, maxRows: 12 }"
            @keydown="handleKeyDown">
          </a-textarea>
          <div style="position: absolute; right: 10px; bottom: 10px; z-index: 1;">
            <img src="../assets/发送.png" width="25" alt="" srcset="" @click="sendMessage">
          </div>
        </div>
      </div>

      <div>
        <a-float-button-group shape="circle" style="margin-right: 95%;margin-bottom: 17%; ">
          <a-tooltip placement="right">
            <template #title>
              <span>回到首页</span>
            </template>
            <div class="float-button" @click="goIndex">
              <img src="../assets/首页.png" width="35" alt="" srcset="">
            </div>
          </a-tooltip>
          <a-tooltip placement="right">
            <template #title>
              <span>文档圈</span>
            </template>
            <div class="float-button" @click="gofilequan">
              <img src="../assets/logo.png" width="35" alt="" srcset="">
            </div>
          </a-tooltip>
          <a-tooltip placement="right">
            <template #title>
              <span><img src="../assets/发票无忧小程序码.jpg" width="140" alt="" srcset=""></span>
            </template>
            <div class="float-button">
              <img src="../assets/收票无忧.png" width="30" alt="" srcset="">
            </div>
          </a-tooltip>
        </a-float-button-group>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MarkdownIt from 'markdown-it';
import { v4 as uuidv4 } from 'uuid';


export default {
  data() {
    return {
      messages: [],
      userInput: '',
      currentMessage: '',
      showDownloadUrl: false,
      activeKey: "1",
      libraryText: ""
    };
  },
  watch: {

  },
  mounted() {
    this.userInput = this.$route.query.message || '';
    if (this.userInput) {
      this.sendMessage();
      this.userInput = '';
      // 删除路由参数
      this.$router.replace({ query: {} });
    }
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // 阻止默认的回车换行行为
        this.sendMessage(); // 调用发送消息的方法
      }
    },
    gofilequan() {
      window.location.href = "https://filequan.com";
    },
    gozc() {
      window.location.href = "https://zczcfiles.com.cn/";
    },
    goIndex() {
      this.$router.push("/")
    },
    goFiles() {
      this.$router.push("/file")
    },
    formatMessage(content) {
      const md = new MarkdownIt();
      return md.render(content); // 使用markdown-it解析内容为HTML格式
    },
    typeMessage(message) {
      if (!message) return;
      this.currentMessage += message;
      this.$nextTick(() => {
        const messagesContainer = this.$el.querySelector('.messages');
        if (messagesContainer) {
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
      });
    },
    async sendMessage() {



      if (this.userInput.trim() === '') return;
      // Add user message to the chat history
      this.messages.push({ role: 'user', content: this.userInput });

      this.$nextTick(() => {
        const messagesContainer = this.$el.querySelector('.messages');
        if (messagesContainer) {
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
      });
      this.currentMemo = "正在思考中...";
      // Prepare the request payload
      const payload = {
        content: this.userInput,
        temperature: 0.7,
        user_id: this.getUserId()
      };

      try {
        // Send the request to the AI chat endpoint
        const response = await fetch('https://chat-extract.zczcfiles.com.cn/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        if (!response.ok || !response.body) {
          throw new Error('Network response was not ok');
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let buffer = '';
        let databuffer;

        this.currentMemo = "";

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const textChunk = decoder.decode(value, { stream: true });
          // 调用函数展示接收到的文本内容（这里只是简单添加到currentMessage，在模板中会解析展示）
          this.typeMessage(textChunk);
        }
        // Wait for typing to complete before adding to messages
        this.messages.push({ role: 'assistant', content: this.currentMessage });
        // this.messages[this.messages.length - 1].children = databuffer.docs.map(doc => ({
        //   role: 'assistant',
        //   content: doc
        // }));
        this.currentMessage = ''; // Reset currentMessage after adding to messages

        console.log(this.currentMessage);

      } catch (error) {
        console.error('Error sending message:', error);
      }
      // Clear the user input
      this.userInput = '';
    },
    getUserId() {
      const storedUserId = localStorage.getItem('user_id');
      if (!storedUserId) {
        const newUserId = uuidv4();
        localStorage.setItem('user_id', newUserId);
        return newUserId;
      }
      return storedUserId;
    }
  }
}
</script>

<style>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
  overflow: hidden;
}

.chat-container {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
  height: 100vh;
}

.message-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.messages {
  margin-top: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* 确保消息按列排列 */
  justify-content: flex-start;
  /* 消息从顶部开始排列 */
  width: 1000px;
  padding: 20px;
  background-color: #f5f5f5;
  margin-bottom: 200px;
  overflow-y: auto;
  flex-grow: 1;
  padding: 30px 16px 0px 16px;
}

.message {
  margin-bottom: 15px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.user-message {
  float: right;
  background-color: #F7F9FA;
  color: black;
  padding: 10px;
  border-radius: 10px;
  max-width: 100%;
  text-align: right;
}

.assistant-message {
  float: left;
  background-color: #e9e9e9;
  color: black;
  padding: 10px;
  border-radius: 10px;
  max-width: 100%;
  text-align: left;
}

.current-message {
  margin-top: 10px;
}

.input-container {
  display: flex;
  background-color: #f5f5f5;
  width: 1000px;
  justify-content: center;
  align-items: center;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

img:hover {
  cursor: pointer;
}

.children-container {
  margin-top: 10px;

}

.child-message {
  margin-top: 5px;
  text-align: left;
}


.logo {
  float: left;
  width: 250px;
  text-align: center;
  font-size: 20px;
  margin: 5px 10px 20px -20px;
  display: flex;
  align-items: center;
}

.right {
  float: right;
  display: flex;
  justify-content: space-around;
}

.float-button {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  /* 添加过渡效果 */
}

.float-button:hover {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  /* 改变阴影为内阴影，模拟凹陷效果 */
}
</style>